import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase"; // Adjust the path if needed
import "./portfolio.css";

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState("Development");
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const portfolioCollectionRef = collection(db, "portfolios");
        const querySnapshot = await getDocs(portfolioCollectionRef);
        const fetchedProjects = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProjects(fetchedProjects);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError("Failed to fetch projects.");
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // Filter projects based on the active tab
  const filteredProjects = projects.filter(
    (project) => project.category === activeTab
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
        } else {
          entry.target.classList.remove("active");
        }
      });
    });

    const items = document.querySelectorAll(".portfolio__item");
    items.forEach((item) => observer.observe(item));

    return () => {
      items.forEach((item) => observer.unobserve(item));
    };
  }, [activeTab]);

  // Automatically switch to "UI/UX Design" when loading is finished
  useEffect(() => {
    if (!loading) {
      setActiveTab("UI/UX Design");
    }
  }, [loading]);

  if (loading)
    return (
      <section id="portfolio">
        <h5>My Recent Work</h5>
        <h2>Portfolio</h2>

        {/* Tab Navigation */}
        <div className="portfolio__tabs">
          <button
            className={activeTab === "Development" ? "active" : ""}
            onClick={() => setActiveTab("Development")}
          >
            Development
          </button>
          <button
            className={activeTab === "UI/UX Design" ? "active" : ""}
            onClick={() => setActiveTab("UI/UX Design")}
          >
            UI/UX Design
          </button>
        </div>

        <div className="container portfolio__container">
          {/* Skeleton Placeholders */}
          {[...Array(6)].map((_, index) => (
            <article className="portfolio__item skeleton" key={index}>
              <div className="portfolio__item-image skeleton-image"></div>
              <div className="portfolio__item-content">
                <div className="skeleton-text skeleton-title"></div>
                <div className="skeleton-text skeleton-description"></div>
                <div className="skeleton-text skeleton-technology"></div>
              </div>
              <div className="portfolio__item-cta skeleton-cta"></div>
            </article>
          ))}
        </div>
      </section>
    );

  if (error) return <p>{error}</p>;

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      {/* Tab Navigation */}
      <div className="portfolio__tabs">
        <button
          className={activeTab === "Development" ? "active" : ""}
          onClick={() => setActiveTab("Development")}
        >
          Development
        </button>
        <button
          className={activeTab === "UI/UX Design" ? "active" : ""}
          onClick={() => setActiveTab("UI/UX Design")}
        >
          UI/UX Design
        </button>
      </div>

      <div className="container portfolio__container">
        {filteredProjects.length ? (
          filteredProjects.map((pro) => (
            <article className="portfolio__item" key={pro.id}>
              <div className="portfolio__item-image">
                <img src={pro.imageUrl} alt={pro.title} />
              </div>
              <div className="portfolio__item-content">
                <h3>{pro.title}</h3>
                <p>{pro.description}</p>
                <p>{pro.technology}</p>
              </div>
              <div className="portfolio__item-cta">
                <a
                  href={pro.link}
                  target="_blank"
                  className="btn btn-primary"
                  rel="noreferrer"
                >
                  {pro.category === "UI/UX Design" ? "See on Figma" : "Live Demo"}
                </a>
              </div>
            </article>
          ))
        ) : (
          <p>No projects available</p>
        )}
      </div>
    </section>
  );
};

export default Portfolio;
