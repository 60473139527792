import React, { useState, useEffect } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { db } from "../../firebase"; // Make sure this path is correct
import { collection, getDocs } from "firebase/firestore";
import "./experience.css";

const Experience = () => {
  const [skills, setSkills] = useState([]);

  const skillsCollectionRef = collection(db, "skills");

  // Fetch skills from Firestore
  const fetchSkills = async () => {
    try {
      const data = await getDocs(skillsCollectionRef);
      setSkills(data.docs.map((doc) => doc.data().skill));
    } catch (err) {
      console.error("Error fetching skills:", err);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  return (
    <section id="experience">
      <h5>The Skills I Have</h5>
      <h2>Skills</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <div className="experience__content">
            {skills.map((skill, index) => (
              <article key={index} className="experience__details">
                <BsFillPatchCheckFill className="experience__details-icon" />
                <h4>{skill}</h4>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
