import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore"; 
import { db } from "../../firebase";
import "../galery/galery.css";

function Galery() {
  const [activeTab, setActiveTab] = useState("Potrait");
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "gallery"));
        const galleryData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(galleryData)
        setData(galleryData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleMediaClick = (item) => {
    setSelectedMedia(item);
  };

  const closeModal = () => {
    setSelectedMedia(null);
  };

  const filteredData = data.filter((item) => item.type === activeTab);

  return (
    <section id="galery">
      <h5>My Recent Activity</h5>
      <h2>Gallery</h2>

      {/* <div className="galery__tabs">
        <button
          className={activeTab === "Potrait" ? "active" : ""}
          onClick={() => setActiveTab("Potrait")}
        >
          Potrait
        </button>
        <button
          className={activeTab === "Landscape" ? "active" : ""}
          onClick={() => setActiveTab("Landscape")}
        >
          Landscape
        </button>
      </div> */}

      <div className="galery__container">
        {loading ? (
          <>
            <div className="skeleton"></div>
            <div className="skeleton"></div>
            <div className="skeleton"></div>
            <div className="skeleton"></div>
            <div className="skeleton"></div>
          </>
        ) : data.length === 0 ? (
          <p>No gallery items to display.</p>
        ) : (
          data.map((item) => (
            <div
              key={item.id}
              className="galery__item"
              onClick={() => handleMediaClick(item)}
            >
              {item.mediaType === "image" ? (
                <img src={item.media} alt={item.title} className="galery__item-image" />
              ) : (
                <video controls className="galery__item-video">
                  <source src={item.media} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ))
        )}
      </div>

      {selectedMedia && (
        <div className="modal">
          <div className="modal__content">
            <span className="modal__close" onClick={closeModal}>
              &times;
            </span>
            {selectedMedia.mediaType === "image" ? (
              <img src={selectedMedia.media} alt={selectedMedia.title} />
            ) : (
              <video controls>
                <source src={selectedMedia.media} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <div className="desc">
              <h3>{selectedMedia.title}</h3>
              <p>{selectedMedia.description}</p>
            </div>
          </div>
          <div className="modal__overlay" onClick={closeModal}></div>
        </div>
      )}
    </section>
  );
}

export default Galery;
