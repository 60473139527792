import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Adjust the import path as needed
import { doc, getDoc } from 'firebase/firestore';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import './header.css';

const Header = () => {
  const [role, setRole] = useState(''); // Default role

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const docRef = doc(db, 'about', 'role');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setRole(docSnap.data().role || ''); // Update role from Firestore
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching role:', error);
      }
    };

    fetchRole();
  }, []);

  return (
    <header id="home">
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Muhammad Reziq Darusman</h1>
        <h5 className="text-light">{role}</h5> {/* Dynamic role */}
        <CTA />
        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
        <HeaderSocials />
      </div>
    </header>
  );
};

export default Header;
