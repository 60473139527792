// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut,  } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyASHKAq-DmSO6DYXtioQUlB7ov5pjJoiaU",
    authDomain: "webportfolio-aa85a.firebaseapp.com",
    projectId: "webportfolio-aa85a",
    storageBucket: "webportfolio-aa85a.appspot.com",
    messagingSenderId: "374134101820",
    appId: "1:374134101820:web:fa679648866a81db9fb039"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const storage = getStorage(app);
  const db = getFirestore(app);
  
  
  export { app, auth, storage, db,signInWithEmailAndPassword, signOut, ref };
