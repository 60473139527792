import React, { useEffect, useRef, useState } from "react";
import { db } from "../../firebase"; // Adjust the path as necessary
import { doc, getDoc } from "firebase/firestore";
import "./intro.css";
import profilSkeleton from "../../assets/profile-skeleton.png"

const Intro = () => {
  const [description, setDescription] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const aboutRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "about", "Ni9BiPPV3bOlN4SHhjCs");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDescription(docSnap.data().description || "");
          setPhotoUrl(docSnap.data().photoUrl || "");
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error("Error fetching document:", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const aboutSection = aboutRef.current;
    if (aboutSection) {
      observer.observe(aboutSection);
    }

    return () => {
      if (aboutSection) {
        observer.unobserve(aboutSection);
      }
    };
  }, []);

  return (
    <section id="about" ref={aboutRef}>
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            {photoUrl ? (
              <img src={photoUrl} alt="Profile" />
            ) : (
              <img src={profilSkeleton} alt="Default Profile" />
            )}
          </div>
        </div>
        <div className="about__content">
          <p>{description}</p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default Intro;
