import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Adjust the import path as needed
import { doc, getDoc } from 'firebase/firestore';

const CTA = () => {
  const [cvLink, setCvLink] = useState('');

  useEffect(() => {
    const fetchCvLink = async () => {
      try {
        const docRef = doc(db, 'about', 'LinkCv');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setCvLink(docSnap.data().link || ''); // Set CV link from Firestore
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching CV link:', error);
      }
    };

    fetchCvLink();
  }, []);

  return (
    <div className="cta">
      <a href={cvLink} download className="btn">
        Download CV
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's talk
      </a>
    </div>
  );
};

export default CTA;
